import { Modal } from "@Shared/ui/Modal";
import { FC, useId } from "react";
import { Form, Row } from "react-bootstrap";
import styles from "./SelectRoleModal.module.scss";
import useTranslation from "next-translate/useTranslation";
import { useYup } from "@Shared/lib/yup/validation";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "@Shared/ui/Select";
import { AsyncGarSelect, GarRequestsEnum } from "@Entities/AsyncGarSelect";
import { degreeList, foreignerDegreeList } from "@Shared/consts/degreeList";
import FormBorder from "@/components/_Common/Form/Border";
import { useForceUpdate } from "@Shared/hooks/useForceUpdate";
import {
  UserActions,
  getStudentDetails,
  getStudentDetailsActiveUserId,
  getUserId,
  postUserCitizenship,
  setStudentdetails,
} from "@Entities/User";
import { FetchPersonalData } from "@Features/PersonalForm";
import { setChosenPrograms } from "@Entities/Form";
import { api } from "@Shared/api/createAxiosApi";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";

interface SelectRoleModalProps {
  showModal: boolean;
  closeModal: () => void;
}

interface SelectRoleFormType {
  citizenship: MapItem<string>;
  degree: MapItem<string>;
}

export const SelectRoleModal: FC<SelectRoleModalProps> = (props) => {
  const { showModal, closeModal } = props;

  const { t, lang } = useTranslation("modals");

  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const studentDetailsList = useSelector(getStudentDetails);
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const formId = useId();

  const yup = useYup();
  const schema = yup.object().shape({
    degree: yup.mixed().required(),
    citizenship: yup.mixed().required(),
  });
  const { control, formState, handleSubmit } = useForm<SelectRoleFormType>({
    resolver: yupResolver(schema) as any,
    mode: "onChange",
  });

  const onError = () => {
    forceUpdate();
  };

  const onSubmit = async (data: SelectRoleFormType) => {
    const { degree, citizenship } = data;

    const studentDetailsActive = studentDetailsList.find(
      (item: IStudentDetails) => {
        return item.degree === degree.value;
      }
    );
    if (!studentDetailsActive) {
      await api.put(`/users/${userId}/studentDetails/`, {
        degree: degree.value,
        first_time_degree: false,
      });
      const response = await api.get(`/users/${userId}/studentDetails/active`);
      const data = response.data.result;
      const payload = { ...data, active: true };
      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(
        postUserCitizenship({ body: { citizenship: citizenship.value } })
      );
      dispatch(FetchPersonalData({ userId, studentDetailsId: payload.id }));
      dispatch(setChosenPrograms({ userId, studentDetailsId: payload.id }));
      dispatch(setStudentdetails({ userId }));
      closeModal();
    }
    if (studentDetailsActive) {
      const payload = { ...studentDetailsActive, active: true };
      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(
        postUserCitizenship({ body: { citizenship: citizenship.value } })
      );
      dispatch(FetchPersonalData({ userId, studentDetailsId }));
      dispatch(setChosenPrograms({ userId, studentDetailsId }));
      closeModal();
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      id="welcomeModal"
      showModal={showModal}
      type="non-closable"
    >
      <Form
        className={styles.modalContainer}
        id={formId}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <h2 className={styles.title}>{t("roles.hello")}</h2>
        <div className={styles.select}>
          <Controller
            control={control}
            defaultValue={undefined}
            name="citizenship"
            render={({ field }) => (
              <AsyncGarSelect
                id="citizenshipSelect"
                placeholder={t("roles.citizenship")}
                requestDataType={GarRequestsEnum.Countries}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors?.degree && (
            <Form.Control.Feedback type="invalid">
              {(formState.errors.degree as FieldError).message}
            </Form.Control.Feedback>
          )}
        </div>
        <div className={styles.select}>
          <Controller
            control={control}
            defaultValue={undefined}
            name="degree"
            render={({ field }) => (
              <Select
                id="degreeSelect"
                options={lang === LanguagesLocalesEnum.RU ? degreeList : foreignerDegreeList}
                placeholder={t("roles.degree")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors?.degree && (
            <Form.Control.Feedback type="invalid">
              {(formState.errors.degree as FieldError).message}
            </Form.Control.Feedback>
          )}
        </div>
        <FormBorder />
        <div>
          <span>{t("roles.text")}</span>
        </div>
        <Row>
          <ButtonCustom
            className={styles.button}
            id={formId}
            rounded={true}
            size="lg"
            type="submit"
            variant="primary"
          >
            {t("roles.next")}
          </ButtonCustom>
        </Row>
      </Form>
    </Modal>
  );
};
