import { FC, PropsWithChildren, useEffect, useState } from "react";
import { DefaultLayout } from "@Widgets/DefaultLayout";
import { useSelector } from "react-redux";
import { getUserDegree } from "@Entities/User";
import { SelectRoleModal } from "../SelectRoleModal/SelectRoleModal";
import { DynamicModuleLoader, ReducersList } from "@Shared/lib/DynamicModuleLoader";
import { PersonalReducer } from "@Features/PersonalForm";

const reducers: ReducersList = {
  personalReducer: PersonalReducer,
};

export const PersonalMainLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const degree = useSelector(getUserDegree);
  const [show, setShow] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!degree) {
      setShow(true);
    }
  }, []);

  return (
    <DynamicModuleLoader reducers={reducers}>
      <DefaultLayout className='form_main'>
        <SelectRoleModal
          closeModal={handleCloseModal}
          showModal={show}
        />
        {children}
      </DefaultLayout>
    </DynamicModuleLoader>
  );
};
