import { NextRouter } from "next/router";

interface IsActiveTabOptions {
    router: NextRouter,
    currenPath: string
}

const minAbs = (...args: number[]): number => {
  const min = Math.min(...args.filter((num) => num > 0));
  return min !== Infinity ? min : 0;
};

export const getSsActiveTab = (options: IsActiveTabOptions): boolean => {
  const { currenPath, router } = options;

  const endOfRoute = minAbs(
    router.asPath.lastIndexOf("?"),
    router.asPath.length
  );

  return router.asPath.substring(0, endOfRoute) === currenPath;
};
