import clsx from "clsx";
import { FC } from "react";
import { Nav } from "react-bootstrap";

import { useSelector } from "react-redux";
import { getUserTheme } from "@Entities/User";
import Link from "next/link";
import { PersonalTabItemType } from "../../types/personalTabsTypes";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { ThemeEnum } from "@Shared/types/themeTypes";
import { getSsActiveTab } from "../../lib/getSsActiveTab";
import styles from "./PersonalTabItem.module.scss";

interface PersonalTabItemProps {
    item: PersonalTabItemType
}

export const PersonalTabItem: FC<PersonalTabItemProps> = (props) => {
  const { item } = props;

  const { t } = useTranslation("common");
  const router = useRouter();
  const theme = useSelector(getUserTheme);

  const isActiveTab = getSsActiveTab({ router, currenPath: item.link });
  const linkClasses: Record<string, boolean> = {
    [styles.tabs_lk__item_linkAccessible]: theme === ThemeEnum.ACCESSIBLE,
    [styles.tabs_lk__item_link]: theme !== ThemeEnum.ACCESSIBLE,
    [styles.tabs_lk__item_activeAccessible]: isActiveTab && theme === ThemeEnum.ACCESSIBLE,
    [styles.tabs_lk__item_active]: isActiveTab && theme !== ThemeEnum.ACCESSIBLE,
  };

  const navigationClasses: Record<string, boolean> = {
    [styles.tabs__item_accessible]: theme === ThemeEnum.ACCESSIBLE,
  };

  return (
    <Nav.Item className={clsx(styles.tabs__item, styles.tabs_lk__item, navigationClasses)} id={`${item.value}TabBlock`}>
      <Link
        className={clsx(styles.tabs__item_link, linkClasses)}
        href={item.link}
        id={`${item.value}TabLink`}
        scroll={false}
      >
        {t(`tabs.heading.${item.value}`)}
      </Link>
    </Nav.Item>
  );
};
