import { PersonalTabItemType } from "../types/personalTabsTypes";

export const personalTabOptions: PersonalTabItemType[] = [
  {
    value: "home",
    link: "/",
  },
  {
    value: "programs",
    link: "/programs",
  },
];
