import { FC } from "react";
import { Nav } from "react-bootstrap";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getUserTheme } from "@Entities/User";
import styles from "./PersonalTabs.module.scss";
import { PersonalTabItem } from "../PersonalTabItem/PersonalTabItem";
import { personalTabOptions } from "../../lib/personalTabOptions";
import { ThemeEnum } from "@Shared/types/themeTypes";
import { AbiturientInformation } from "@Entities/AbiturientInformation";

export const PersonalTabs: FC = () => {
  const theme = useSelector(getUserTheme);

  const navigationClasses: Record<string, boolean> = {
    [styles.tabs_lk_accessible]: theme === ThemeEnum.ACCESSIBLE,
  };

  return (
    <Nav className={clsx(styles.tabs, styles.tabs_lk, navigationClasses)}>
      <Container className="d-none d-sm-flex px-3">
        <AbiturientInformation />
        <div className="d-flex" id={"personalTabs"}>
          {personalTabOptions.map((item, i) => (
            <PersonalTabItem item={item} key={i} />
          ))}
        </div>
      </Container>
    </Nav>
  );
};
